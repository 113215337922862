import React from "react";
import { Divider, message } from "antd";
import { observer, inject } from "mobx-react";

@inject("user")
@observer
class view extends React.Component {
  constructor() {
    super();
    this.loading = false;
  }

  state = {
    timer: 60
  };

  /**发送验证码 */
  sendCode = () => {
    let { getLoginCode } = this.props;
    if (this.loading) {
      return;
    }

    getLoginCode().then(option => {
      Service.user
        .sendVerifyCode(option)
        .then(res => {
          this.loading = true;
          message.info(`已发送验证码到${res.data.mobile}`);
          let intTimer = setInterval(() => {
            let { timer } = this.state;
            timer--;
            if (timer === 0) {
              clearInterval(intTimer);
              this.setState({ timer: 60 });
              this.loading = false;
              return;
            }
            this.setState({ timer });
          }, 1000);
        })
        .catch(err => {
          let msgStr;
          try {
            msgStr = err.data.message;
          } catch (e) {
            msgStr = "验证码发送失败，请稍后重试";
          }
          message.warn(msgStr);
          this.loading = false;
        });
    });
  };

  render() {
    const { timer } = this.state;
    return (
      <div className="login-send-code">
        <Divider type="vertical" />
        {timer === 60 ? <span onClick={this.sendCode}>获取验证码</span> : `${timer}s`}
      </div>
    );
  }
}

export default view;
