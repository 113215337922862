import React from 'react'
import { observer, inject } from 'mobx-react'
import Base64 from 'js-base64'
import DefaultLogin from './default'
import CustomLogin from './custom'

import './index.less'

const Loading = Loader.Loading

const { systemLogo, systemName, parterLogo2, parterLogo1, loginBackground, icp, loginMode } = window.BSConfig.systemConfig
const isDefaultMode = loginMode === 'default'

@inject('user')
@observer
class LoginView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      systemInfo: {
        systemName,
        systemLogoUrl: systemLogo,
        isHideLogo: false,
        copyRight: icp,
        partnerLogoUrl: parterLogo2,
        lyLogo: parterLogo1,
        customLoginBgUrl: loginBackground
      },
      isInit: false
    }
  }
  componentWillMount() {
    let { user, history, match } = this.props
    const isLogin = user.isLogin
    if (isLogin) {
      history.replace('/')
      return
    } else {
      sessionStorage.removeItem('token')
    }
    const options = {
      customDomainName: window.location.hostname,
      loginKeyUrl: match.params.id,
      loginKey: Base64.encode(match.params.id)
    }

    Service.operation
      .getOperationCenterLogo(options)
      .then(res => {
        if (!res.data) {
          return
        }
        const options = this.state.systemInfo
        for (let k in options) {
          if (res.data.hasOwnProperty(k) && res.data[k]) {
            options[k] = res.data[k]
          }
        }
        document.title = options.systemName
        this.setState({
          systemInfo: options,
          isInit: true
        })
      })
      .catch(() => {
        document.title = this.state.systemInfo.systemName
        this.setState({
          isInit: true
        })
      })
  }

  render() {
    let { isInit, systemInfo } = this.state
    if (!isInit) {
      return <Loading />
    }
    return isDefaultMode ? <DefaultLogin {...this.props} {...systemInfo} /> : <CustomLogin {...this.props} {...systemInfo} />
  }
}

export default LoginView
