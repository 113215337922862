(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("mobxReact"), require("Base64"));
	else if(typeof define === 'function' && define.amd)
		define("login", ["React", "antd", "mobxReact", "Base64"], factory);
	else if(typeof exports === 'object')
		exports["login"] = factory(require("React"), require("antd"), require("mobxReact"), require("Base64"));
	else
		root["login"] = factory(root["React"], root["antd"], root["mobxReact"], root["Base64"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__) {
return 