import React from "react";
import { observer, inject } from "mobx-react";
import { Form, Button, Input, message } from "antd";
import CodeView from "./components/code";

import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const ModifyPassword = Loader.loadBusinessComponent("ModifyPassword");
const FormItem = Form.Item;

@Form.create({})
@inject("user")
@observer
class LoginView extends React.Component {
  state = {
    changePwdVisible: false,
    loginLoading: false,
    passwordType: 0
  };
  /**获取验证码 */
  getLoginCode = () => {
    const { form } = this.props;
    return new Promise((resolve, reject) => {
      form.validateFields(["loginName", "userPassword"], (err, value) => {
        if (err) {
          console.error("表单错误", err);
          reject(err);
        }
        let option = {
          loginName: value.loginName,
          userPassword: value.userPassword
        };
        resolve(option);
      });
    });
  };

  // 登录
  submitEvent = e => {
    e.preventDefault();
    const { user, history, form, location, match } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        console.error("表单错误", err);
        return;
      }
      this.setState({
        loginLoading: true
      });
      const loginName = values.loginName;
      let params = {
        loginName,
        userPassword: values.userPassword,
        identifyCode: values.identifyCode
      };

      Service.user
        .login(params)
        .then(res => {
          Utils.setCache("token", res.data.token, "session");
          Utils.setCache("userId", res.data.userId, "session");
          if (match.params.id) {
            Utils.setCache("loginType", match.params.id, "session");
          }
          if (res.data.isModifyPassWord == 0 || res.data.isModifyPassWord == 2) {
            this.setState({
              changePwdVisible: true,
              loginLoading: false,
              passwordType: res.data.isModifyPassWord
            });
            return;
          }
          Service.logger.save({
            ...Service.url.request.login.logInfo[0],
            description: `【${loginName}】登录系统`
          });
          user.setLoginState(true);
          let params = Utils.queryFormat(location.search);
          history.replace(params.redirect ? decodeURIComponent(params.redirect) : "/");
        })
        .catch(err => {
          let msg;
          try {
            msg = err.data.message ? err.data.message : "登录错误！";
          } catch (e) {
            console.error(e);
          }
          message.warning(msg);
          this.setState({
            loginLoading: false
          });
        });
    });
  };

  /**
   * @desc 取消修改密码弹框
   * @params {boolean} isChangePassWord 是否修改成功
   */
  closeChangePwdMark = isChangePassword => {
    if (isChangePassword) {
      // 如果修改密码成功，清空form表单密码和验证码输入框
      this.props.form.setFieldsValue({
        userPassword: "",
        identifyCode: ""
      });
    }
    this.setState({
      changePwdVisible: false
    });
  };

  render() {
    let { loginLoading, passwordType } = this.state;
    const { systemLogoUrl, systemName, isHideLogo, copyRight, partnerLogoUrl, lyLogo } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="user_login_layout">
        <div className="user_login_view">
          <div className="top-part">
            <div className="line-top" />
            <video muted width="100%" height="auto" src="/resource/media/video.mp4" autoPlay loop />
          </div>
          <div className="user_login_content">
            <div className="login_content_title">
              <i className="title_icon">
                <img src={systemLogoUrl} />
              </i>
              <span className="title_text">{systemName}</span>
            </div>
            <div className="login_content_info">
              <Form size="large" onSubmit={this.submitEvent}>
                <FormItem label="用户名">
                  {getFieldDecorator("loginName", {
                    rules: [
                      {
                        required: true,
                        message: `请输入用户名`
                      }
                    ]
                  })(
                    <Input
                      prefix={
                        <IconFont type={"icon-S_Tree_People"} style={{ fontSize: "24px", color: "#8899bb" }} theme="outlined" />
                      }
                      autoComplete="off"
                      name="user"
                      placeholder={"请输入用户名"}
                    />
                  )}
                </FormItem>
                <FormItem label="密码">
                  {getFieldDecorator("userPassword", {
                    rules: [
                      {
                        required: true,
                        message: `密码是必填项`
                      }
                    ]
                  })(
                    <Input
                      prefix={
                        <IconFont
                          type={"icon-M_Login_PassWord"}
                          style={{ fontSize: "24px", color: "#8899bb" }}
                          theme="outlined"
                        />
                      }
                      autoComplete="new-password"
                      name="password"
                      type="password"
                      placeholder={"请输入密码"}
                    />
                  )}
                </FormItem>
                <FormItem label="验证码" className="login_info_message">
                  {getFieldDecorator("identifyCode", {
                    rules: [
                      {
                        required: true,
                        message: `请输入验证码`
                      },
                      {
                        max: 6,
                        message: "请输入正确的验证码"
                      }
                    ]
                  })(
                    <Input
                      prefix={
                        <IconFont
                          style={{ fontSize: "24px", color: "#8899bb" }}
                          type={"icon-M_Login_PhoneNum"}
                          theme="outlined"
                        />
                      }
                      autoComplete="new-password"
                      name="identifyCode"
                      type="text"
                      placeholder={"请输入验证码"}
                    />
                  )}
                  <CodeView user={this.props.user} getLoginCode={this.getLoginCode} />
                </FormItem>
                <Form.Item className="login-btn-con">
                  <Button loading={loginLoading} size="large" type="primary" htmlType="submit" className="login-btn mt10">
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <div className="user_login_footer">
            <div className="login_footer_box">
              <div className="img_box">
                {isHideLogo != 1 && !!lyLogo && (
                  <span
                    className="footer_img"
                    style={{
                      background: `url(${lyLogo}) ${partnerLogoUrl ? "right" : "center"} center no-repeat`
                    }}
                  />
                )}
                {partnerLogoUrl && (
                  <span
                    className="footer_img"
                    style={{
                      background: `url(${partnerLogoUrl}) ${
                        isHideLogo != 1 ? (lyLogo ? "left" : "center") : "center"
                      } center no-repeat`
                    }}
                  />
                )}
              </div>
              <p className="footer_text">
                为获得最佳使用体验，建议使用谷歌浏览器最新版，并在分辨率为1920×1080的显示器上显示
                <a
                  className="footer-logo-google-link"
                  target="_blank"
                  href="https://www.google.cn/chrome/"
                  rel="noopener noreferrer"
                >
                  下载Chrome浏览器
                </a>
                <br />
                {copyRight}
              </p>
            </div>
          </div>
          <ModifyPassword
            visible={this.state.changePwdVisible}
            isRedirect={false}
            title={passwordType === 0 ? "检测到您初次登录,请修改密码" : "检测您的密码为弱密码,请修改密码"}
            maskClosable={false}
            closable={false}
            onCancel={this.closeChangePwdMark}
          />
        </div>
      </div>
    );
  }
}

export default LoginView;
